<template>
  <div style="padding: 16px;">
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <div class="ucid-row">
          <el-input v-model="ucidAdd" placeholder="ucid" size="normal" clearable></el-input>
          <el-button type="primary" size="default" :loading="addLoading" @click="handleAddUCID">
            添加
          </el-button>
        </div>
      </el-col>
      <el-col :span="12" :offset="0">
        <div class="ucid-row justify-end">
          <el-input v-model="ucidQuery" placeholder="ucid" size="normal" clearable></el-input>
          <el-button size="default" @click="handleQueryUCID">查询</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="title">已加V名单</div>
    <div>
      <el-table :data="list" border stripe>
        <el-table-column label="UCID" prop="ucid"> </el-table-column>
        <el-table-column label="昵称" prop="nickname"></el-table-column>
        <el-table-column label="手机" prop="mobile"></el-table-column>
        <el-table-column label="头像" prop="avatar">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.avatar"
              :src="scope.row.avatar"
              fit="fill"
              :lazy="true"
              :preview-src-list="[scope.row.avatar]"
              style="max-width: 80px; max-height: 80px; border-radius: 50%"
            ></el-image>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="个性签名" prop="introduction"></el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleClickDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getVipUserList, delVipUser, addVipUser } from "@/api/star-admin.js";

export default {
  data() {
    return {
      ucidAdd: "",
      ucidQuery: "",
      addLoading: false,
      delLoading: false,
      list: [],
      page: 1,
      pageSize: 20,
      total: 0,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList();
    },
    async handleQueryUCID() {
      this.page = 1;
      this.fetchList();
    },
    async handleAddUCID() {
      if (this.addLoading) {
        return;
      }
      if (!this.ucidAdd) {
        this.$message.info("请输入UCID");
        return;
      }

      this.addLoading = true;
      try {
        await addVipUser(this.ucidAdd);
        this.$message.success("添加成功");
        this.fetchList();
      } catch (error) {
        console.log(error);
      } finally {
        this.addLoading = false;
      }
    },
    handleClickDelete(data) {
      this.$confirm(`删除加V用户 ${data.nickname || data.ucid}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDelete(data);
      });
    },
    async handleDelete(data) {
      if (this.delLoading) {
        return;
      }

      this.delLoading = true;
      try {
        await delVipUser(data.ucid);
        this.$message.success("删除成功");
        this.fetchList();
      } catch (error) {
        console.log(error);
      } finally {
        this.delLoading = false;
      }
    },
    async fetchList() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
        };
        if (this.ucidQuery) {
          params["search_ucid"] = this.ucidQuery;
        }
        const { data } = await getVipUserList(params);
        if (Array.isArray(data?.data?.items)) {
          this.list = data.data.items;
          this.total = data.data.total || 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ucid-row {
  display: flex;
  align-items: center;

  .el-input {
    width: 200px;
    margin-right: 12px;
  }
}

.justify-end {
  justify-content: end;
}

.title {
  font-size: 15px;
  font-weight: bold;
  margin: 32px 0 16px 0;
}
</style>
