import config from "@/configs/index";
import axios from "axios";

// 获取表情包列表
export function getEmojiList(data) {
  return axios({
    url: config.url + "/ikki/admin_social/get_emoticon_list",
    method: "post",
    data,
  });
}

// 设置表情包上下架状态
export function setEmojiStatus(data) {
  return axios({
    url: config.url + "/ikki/admin_social/set_emoticon_status",
    method: "post",
    data,
  });
}
// 获取表情包列表
export function getMockEmojiList(data) {
  return axios({
    url: "https://mock.apifox.cn/m1/1524419-0-59be01ab/ikki/admin_social/get_emoticon_list",
    method: "post",
    data,
  });
}

// 设置表情包上下架状态
export function setMockEmojiStatus(data) {
  return axios({
    url: "https://mock.apifox.cn/m1/1524419-0-59be01ab/ikki/admin_social/set_emoticon_status",
    method: "post",
    data,
  });
}

// 查询星笺加V用户列表
export function getVipUserList(data) {
  console.log(data);
  return axios({
    url: config.url + "/ikki/admin_social/get_user_v_list",
    method: "post",
    data,
  });
}

// 星笺添加加V用户
export function addVipUser(ucid) {
  return axios({
    url: config.url + "/ikki/admin_social/add_user_v",
    method: "post",
    data: {
      v_ucid: ucid,
    },
  });
}

// 星笺删除加V用户
export function delVipUser(ucid) {
  return axios({
    url: config.url + "/ikki/admin_social/del_user_v",
    method: "post",
    data: {
      v_ucid: ucid,
    },
  });
}

///ikki/admin_social/del_user_v
